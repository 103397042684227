import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import TrustedBy from "../components/hireVa/trusted"
import SaveTime from "../components/hireVa/savetime"
import GoodChoice from '../components/hireVa/goodchoice'
import PostingRole from '../components/hireVa/postingrole'
import Assistant from '../components/hireVa/assistant'
import Booking from '../components/hireVa/booking'

import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import GetInTouch from  '../components/hireVa/getintouch'
import { useStaticQuery, graphql, Link } from "gatsby"



const HrService = ({ data }) => { 
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      const banner_title  = ['Fully Managed', 3000, 'Fully Trained ', 2000];
      const banner_button = 'Hire Vetted Virtual Assistant';


return (

    <Layout>
<Seo title="Find the best freelance Virtual Assistants as per Demand  " />

<Banner title = {banner_title} sticky_first = "Hire" sticky_second  = "Human Resource" button = {banner_button} />
<section className = "inro" >
<div className = "container">
  <p>All of our human resource va are trained in exclusive in house boot camps</p>
</div>
</section>


{/* 
        <div className = "vr_banner inner">
        <StaticImage
                            src="../images/HR--banner.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>Let Human Resource specialists handle your Staff </h1>
            <p>
            Delegate all your work force issues to Human Resource VA. Our global HR VA are flexible and trained for all kinds of HR Work.   
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">Hire Human Resource VA</Link>
        </div>
        </div>


        </div>
*/}

<section className = "trained serv_det">

    <div className="container">

   
        <ul>

        <li>
        <StaticImage
            src="../images/Assembly.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Assembly</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/Bambee.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Bambee</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Bonusly.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Bonusly</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Nectarhr.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Nectarhr</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Confetti.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Confetti</h4>

        </li>
        <li>
        <StaticImage
            src="../images/team.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Team</h4>

        </li>
    </ul>

    </div>


</section>

<TrustedBy/>


<SaveTime/>



<GoodChoice/>

<section className = "trained serv_det">

    <div className="container">

      <h1>WHAT CAN A RECRUITER VIRTUAL ASSISTANT CAN DO?</h1>
      <p>Virtual Assistant provides highly talented virtual recruiting assistants. Hire a Recruitment
           virtual Assistant to do all the heavy lifting. Our VA are highly trained in Formatting Resumes. 
           They can Post Job ads for you can create and streamline Job Descriptions for you. Will reduce your
            efforts by filtering the resumes and screening the candidates in order to find right source for you.
             Our vetted recruitment virtual assistants can manage your social media reputation by handling your Hr 
             Pages and many more.</p>


<div className = "row">

        <ul>

        <li>
        <StaticImage
            src="../images/employees-queries.png"
            quality = "100"
          width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Employees Administrative Queries</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/book-travel.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Book Travel & Accommodations</h4>

        </li>



        <li>
        <StaticImage
            src="../images/create-presentations.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Create Presentations</h4>

        </li>



        <li>
        <StaticImage
            src="../images/manage-contact-list.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Manage Contact List</h4>

        </li>



        <li>
        <StaticImage
            src="../images/market-research.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Perform Market research</h4>

        </li>
        <li>
        <StaticImage
            src="../images/customer-service.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Customer Service</h4>

        </li>

        <li>
        <StaticImage
            src="../images/respond-emails.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Respond to emails & calls</h4>

        </li>
        <li>
        <StaticImage
            src="../images/schedule-meetings.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Schedule meetings</h4>

        </li>
    </ul>
    </div>


    </div>


</section>


<section className = "service_package">

<h1>CHOOSE YOUR PLAN</h1>

<div className = "container">

 

<div class="naccs">
  <div class="grid">
   <div class="gc gc--1-of-3">
    <div class="menu">
     <div class="active"><span class="light"></span><span>Part Time VA</span></div>
     <div><span class="light"></span><span>Dedicated VA</span></div>
   
    </div>
   </div>
   <div class="gc gc--2-of-3">
    <ul class="nacc">
     <li class="active">
      <div className = "inner_data">

        <div className = "list">
        <h2>Part Time VA</h2>
          <span><i class="fa fa-check"></i> Posting jobs or job ads management</span>
          <span><i class="fa fa-check"></i> Reviewing applicant resumes/profiles</span>
          <span><i class="fa fa-check"></i> Performing  administering screening test   </span>
          <span><i class="fa fa-check"></i> Organize meetings٫ travels and accommodations</span>
          <span><i class="fa fa-check"></i> Sending congratulatory email for new hires</span>
          <span><i class="fa fa-check"></i> Maintaining applicant tracking system</span>
          <span><i class="fa fa-times"></i> Maintaining database management of employee files</span>
          <span><i class="fa fa-times"></i> Facilitating new hire orientation</span>
          <span><i class="fa fa-times"></i> Maintaining employee payroll tracking</span>
          <span><i class="fa fa-times"></i> Maintaining employee attendance tracking</span>
          <span><i class="fa fa-times"></i> Updating pipeline development</span>
          <span><i class="fa fa-times"></i> Conduct market research and report on the results</span>
          <span><i class="fa fa-check"></i> Prepare presentations</span>
    

        </div>

        <div className = "start_now">
          <h2>$20</h2>
          <span>Per Hour</span>
          <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">start now</Link>
        </div>
       
     
      </div>
     </li>
     <li>
     <div className = "inner_data">

<div className = "list">
<h2>Dedicated VA</h2>
<span><i class="fa fa-check"></i> Posting jobs or job ads management</span>
          <span><i class="fa fa-check"></i> Reviewing applicant resumes/profiles</span>
          <span><i class="fa fa-check"></i> Performing  administering screening test   </span>
          <span><i class="fa fa-check"></i> Organize meetings٫ travels and accommodations</span>
          <span><i class="fa fa-check"></i> Sending congratulatory email for new hires</span>
          <span><i class="fa fa-check"></i> Maintaining applicant tracking system</span>
          <span><i class="fa fa-check"></i> Maintaining database management of employee files</span>
          <span><i class="fa fa-check"></i> Facilitating new hire orientation</span>
          <span><i class="fa fa-check"></i> Maintaining employee payroll tracking</span>
          <span><i class="fa fa-check"></i> Maintaining employee attendance tracking</span>
          <span><i class="fa fa-check"></i> Updating pipeline development</span>
          <span><i class="fa fa-check"></i> Conduct market research and report on the results</span>
          <span><i class="fa fa-check"></i> Prepare presentations</span>
</div>

<div className = "start_now">
  <h2>$17</h2>
  <span>Per Hour</span>
  <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">start now</Link>
</div>


</div>


     </li>
   
    </ul>
   </div>
  </div>
 </div>

</div>


</section>


<PostingRole/>


<Assistant/>



<Booking/>

<Testimonials/>

    <Faqs/>
    
<GetInTouch/>

 </Layout>

)


}

export default HrService;